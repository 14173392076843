





























































































































































import Vue from 'vue';
import _ from "lodash";

const emptySummary = {
  exclude: 0,
  include: 0,
  quantity: 0,
  tax: 0,
};

export default Vue.component('QCartListTotal', {
  components: {
    QTranslate: () => import("../elements/QTranslate.vue"),
    QSkeleton: () => import("../elements/QSkeleton.vue"),

    SOSimplePrice: () => import("../components/SOSimplePrice.vue"),
  },
  props: {
    isLoading: {
      required: false,
      default: false
    },
    isNaizei: {
      required: true,
    },
    summary: {
      type: Array,
      required: true
    },
    discountPrice8: {
      type: Number,
      required: false,
      default: NaN,
    },
    discountPrice10: {
      type: Number,
      required: false,
      default: NaN,
    },
    totalPrice: {
      type: Number,
      required: false,
      default: NaN,
    },
    totalLabel: {
      required: false,
      default: "",
    },
    wrapperClass: {
      required: false,
      default: "CartListArea TotalArea",
    }
  },
  computed: {
    tax8() {
      const target = _.find(
        this.summary,
        (summary) => summary.tax_rate == 8
      );

      return target || emptySummary;
    },
    tax10() {
      const target = _.find(
        this.summary,
        (summary) => summary.tax_rate == 10
      );

      return target || emptySummary;
    },
    total() {
      const target = _.find(
        this.summary,
        (summary) => summary.tax_rate == null
      );

      return target || emptySummary;
    },
  },
  filters: {
    toPriceString(price: number): string {
        if (price) {
            return price.toLocaleString();
        }

        return '0';
    }
  },
})
